import * as React from "react";
import { Icon, IIconProps } from "./Icon";
import { Logo } from "./Logo";
import { AnimatePresence, motion } from "framer-motion";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import * as Portal from "@radix-ui/react-portal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { agregarToken } from "../../features/ui/uiSlice";

// ---
// Menú que contiene el menú de escritorio y el menú de móvil
// ---
export interface IMenuProps {
  items: IItemMenuProps[];
}

const Menu = ({ items }: IMenuProps): JSX.Element => {
  return (
    <div className="sticky left-0 top-0 flex min-h-full w-min flex-row items-start justify-start gap-1 bg-light shadow-right">
      <div className="relative h-full w-full">
        <MenuEscritorio items={items} />
        <MenuMovil items={items} />
      </div>
    </div>
  );
};

// ---
// End of Menú que contiene el menú de escritorio y el menú de móvil
// ---

// ---
// Menú de escritorio
// ---

export interface IMenuEscritorioProps {
  items: IItemMenuProps[];
}

export const MenuEscritorio = ({
  items,
}: IMenuEscritorioProps): JSX.Element => {
  const navigate = useNavigate();
  const { usuario } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const cerrarSesion = () => {
    dispatch(agregarToken(""));
    navigate("/auth/login", { replace: true });
  };
  return (
    <div
      className={
        "relative hidden h-full w-[240px] shrink-0 flex-col items-start justify-start gap-6 pb-0 pl-0 pr-0 pt-6 md:flex"
      }
    >
      <Link
        to={"/"}
        className={
          "relative flex shrink-0 flex-col items-center justify-start gap-2 self-stretch pb-6 pl-4 pr-4 pt-6"
        }
      >
        <img
          alt="insuranceservices pattern"
          src="/images/logo_proteccion_salud.png"
          width="70%"
        />
      </Link>

      <div
        className={
          "relative flex shrink-0 flex-row items-start justify-start gap-2 self-stretch pb-0 pl-4 pr-4 pt-0"
        }
      >
        {/* <div className={"w-full text-dark text-left"}><span className="bg-primary text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-primary dark:text-white">{user.fc_perfil}</span></div> */}
        <div className={"flex w-full flex-col text-left text-dark"}>
          {usuario && (
            <>
              <span className="mb-2 w-full">
                <span className="mr-2 rounded-full bg-primary px-2.5 py-0.5 text-xs font-medium text-white dark:bg-primary dark:text-white">
                  {/* {usuario.profile.toUpperCase()} */}
                </span>
                <span
                  onClick={cerrarSesion}
                  className="mr-2 rounded-full bg-gray-dark px-2.5 py-0.5 text-xs font-medium text-white dark:bg-gray-dark dark:text-white"
                >
                  Salir
                </span>
              </span>
              <span className="w-full">
                Hola,{" "}
                {usuario &&
                  usuario.fc_nombres &&
                  usuario.fc_nombres.replace(/^\w/, (c: string) =>
                    c.toUpperCase()
                  )}{" "}
                {usuario.fc_apellido_paterno.replace(/^\w/, (c: string) =>
                  c.toUpperCase()
                )}
              </span>
            </>
          )}
        </div>
      </div>

      <motion.div className={"w-full space-y-0"}>
        {items.map((item, i) => (
          <ItemMenu key={i} {...item} />
        ))}
      </motion.div>
    </div>
  );
};

// ---
// End of Menú de escritorio
// ---

// ---
// Menú de móvil
// ---

export interface IMenuMovilProps {
  items: IItemMenuProps[];
}
export const MenuMovil = ({ items }): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Portal.Root>
      <div className={"fixed bottom-4 right-4 !z-[999] md:hidden"}>
        <div className={"relative"}>
          <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
            <PopoverTrigger asChild>
              {/* Icono de menú */}
              <motion.div
                className={
                  "flex h-12 w-12 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-light shadow-xl"
                }
              >
                <Icon variante={isOpen ? "x" : "menu"} color="dark" size={32} />
              </motion.div>
              {/* End of Icono de menú */}
            </PopoverTrigger>
            <PopoverContent className="mr-6 p-0">
              <motion.div className={"w-full space-y-0"}>
                {items?.map((item, i) => (
                  <ItemMenu key={i} {...item} />
                ))}
              </motion.div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </Portal.Root>
  );
};

// ---
// End of Menú de móvil
// ---

// ---
// Item de menú
// ---

export interface IItemMenuProps {
  estado?: "activo" | "inactivo" | "deshabilitado";
  label: string;
  icono: IIconProps["variante"];
  onClick: () => void;
  href: string;
}

const ItemMenu = ({
  estado = "activo",
  label,
  icono,
  href,
}: IItemMenuProps): JSX.Element => {
  const height = 60;
  const location = useLocation();
  return (
    <div className={"relative"}>
      <Link
        to={href || "#"}
        className={
          "relative flex shrink-0 flex-row items-center justify-start gap-4 self-stretch overflow-hidden"
        }
      >
        <motion.div
          className={`relative flex flex-1 flex-row items-center justify-start gap-4 px-4`}
          initial={{ height }}
          animate={{
            height,
            color: estado === "activo" ? "primary" : "dark",
          }}
        >
          <Icon variante={icono} />

          <div className={`relative flex-1 text-left`}>{label}</div>
        </motion.div>
      </Link>
      {/*  Indicador de activo */}
      <AnimatePresence mode="sync">
        {href === location.pathname && (
          <motion.div
            layoutId="menuItemActivoIndicador"
            initial={{ height }}
            className={`absolute inset-0 z-[0] w-full border-l-4 border-primary-light bg-primary-light/20 md:border-l-0 md:border-r-4`}
          />
        )}
      </AnimatePresence>
      {/*  End of Indicador de activo */}
    </div>
  );
};

// ---
// End of Item de menú
// ---

export default Menu;
