import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./App";
import { store } from "./store";
import ReduxToastr from "react-redux-toastr";

const root = ReactDOM.createRoot(document.getElementById("root")!);

if (process.env.NODE_ENV === "development") {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </BrowserRouter>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </BrowserRouter>
    </Provider>
  );
}
